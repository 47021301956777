// Here you can add other styles
// Cosmo 4.3.1
// Bootswatch


// Variables ===================================================================

$web-font-path: "https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,700" !default;
@import url($web-font-path);

// Navbar ======================================================================

// Buttons =====================================================================

// Typography ==================================================================

body {
  -webkit-font-smoothing: antialiased;
  font-size: 1.1rem;
}

.bg-transparent{
  background: transparent
}

.selectable{
  cursor: pointer;
}

.unselectable{
  cursor: not-allowed;
}

.main-h-80 {
  height: 80vh;
  /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 80);
}

.spin{
  animation:spin 2s infinite linear
}

@keyframes spin{
  0%{
    transform:rotate(0deg)
  }
  100%{
    transform:rotate(359deg)
  }
}

.ReactTable .-pagination .-btn {
  background: $blue  !important;
  border:0;
  color:$light !important;
  padding: 3 !important;
}

.ReactTable .-pagination .-previous, .ReactTable .-pagination .-next {
  flex: .15 1 !important
}

.ReactTable .rt-thead.-header {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: $gray-100;
  box-shadow: none !important;
  border-bottom:1px solid $gray-400
}

.rt-resizable-header-content{
  color: $gray-700; 
  text-align: left;
}

.on-hover-show {
  visibility: hidden;
}

.on-hover-show-parent:hover .on-hover-show {
  visibility: visible;
}

.-pagination{
  box-shadow: none !important;
}

.text-gray-600{
  color: $gray-600
}

.bg-dark-blue {
  background-color: $darkBlue
}

.rt-td, .rt-th {
  padding-left:1.5rem !important;
}

.dropdown-item { 
  width: max-content !important;
}

.dropdown-menu {
  max-height: max-content;
  max-width: max-content;
}

.hover-bold:hover {
  font-weight: 600;
}

html:not([dir="rtl"]) .sidebar {
  margin-left:0
}
@media print {
  .sidebar{
    display: none;
  }
}