// Cosmo 4.3.1
// Bootswatch


// Variables ===================================================================

$web-font-path: "https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,700" !default;
@import url($web-font-path);

// Navbar ======================================================================

// Buttons =====================================================================

// Typography ==================================================================

body {
  -webkit-font-smoothing: antialiased;
}

// Tables ======================================================================

// Forms =======================================================================

// Navs ========================================================================

// Indicators ==================================================================

// Progress bars ===============================================================

.progress {
  @include box-shadow(none);
  .progress-bar {
    font-size: 8px;
    line-height: 8px;
  }
}

// Containers ==================================================================
