$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #eee !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #bbb !default;
$gray-600: #666 !default;
$gray-700: #444 !default;
$gray-800: #222 !default;
$gray-900: #212121 !default;
$black:    #000 !default;

// $blue:    #2196F3 !default;
// $indigo:  #6610f2 !default;
// $purple:  #6f42c1 !default;
// $pink:    #e83e8c !default;
// $red:     #e51c23 !default;
// $orange:  #fd7e14 !default;
// $yellow:  #ff9800 !default;
// $green:   #4CAF50 !default;
// $teal:    #20c997 !default;
$darkBlue:    #3F4052 !default;

$blue:    #3077bc !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #d10e00 !default;
$orange:  #f48d22 !default;
$yellow:  #ff9800 !default;
$green:   #118e46 !default;
$teal:    #20c997 !default;
$cyan:    #30a2bc !default;

$primary:       $blue !default;
$secondary:     $darkBlue !default;
$success:       $green !default;
$info:          $darkBlue !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $white !default;
$dark:          $black !default;

// Options

$enable-rounded: false !default;

// Body

$body-color:    $gray-800 !default;

// Fonts

$font-family-sans-serif: "Segoe UI", "Source Sans Pro", Calibri, Candara, Arial, sans-serif !default;

$font-size-base: 0.9375rem !default;

$headings-font-weight:   300 !default;

// Navbar

$navbar-dark-hover-color:           rgba($white,1) !default;

$navbar-light-hover-color:          rgba($black,.9) !default;

// Alerts

$alert-border-width:                0 !default;

// Progress bars

$progress-height:               0.5rem !default;
