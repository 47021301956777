// Cosmo 4.3.1
// Bootswatch


// Variables ===================================================================

$web-font-path: "https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,500,600,700, 800" !default;
@import url($web-font-path);

// Navbar ======================================================================

// Buttons =====================================================================

// Typography ==================================================================

body {
  -webkit-font-smoothing: antialiased;
}

// Tables ======================================================================

// Forms =======================================================================

// Navs ========================================================================

// Indicators ==================================================================

// Progress bars ===============================================================

.progress {
  @include box-shadow(none);
  .progress-bar {
    font-size: 8px;
    line-height: 8px;
  }
}

// Containers ==================================================================
.sidebar {
  background-color: $gray-800;
  color:$light;
}

.app-header .navbar-brand {
  width:75px
}

.list-group-item {
  background-color: transparent;
}

.sub-sidebar {
  background-color: $gray-300;
  width: 290 !important;
  margin-top: -16px;
}

.input-group-text {
  background-color: transparent;
}
.nav-link{
  color: $gray-800;
}


a {
  text-decoration: none !important;
}
// Graph Responsive styles
.performanceAcrossAllGrowers {
  height: 37vh;
}
.vsGraphs {
  height: 40vh;
}
.growerPerformance {
  height: 28vh;
}


@media (min-width: 992px){
  html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed .main {
    margin-left: 0px;
  }
  
}
@media (max-width: 576px) { 
  .sidebar {
    max-width:90px;
    font-size: 12px;
    padding:0 !important;
  }
  .virtualSeasonContanier{
    margin-left: 100px !important;
  }
  .virtualSeasonDropdowns{  
    flex-direction: column;
    margin-right: 0px !important;
    font-size: 12px;
  }
  .vsDropdown{
    margin-right: 0px !important;
  }
  .summaryResults{
    flex-direction: column;
  }
  .sustainabilityDropdowns{
    margin-right: 0.5rem !important;
  }
  .quartile{
    flex-direction: column;
    display: flex;
  }
  .quartileGraphs {
    max-width: 100% !important;
  }
  .vsContainer {
    padding: 0px 10px !important;
  }

 }